<template>
    <v-container fluid>
        <v-card class="mx-auto 2xl:tw-w-1/2 tw-px-6 tw-py-8 mb-6">
            <v-card-title>
                Ordering
            </v-card-title>
            <v-card-text>
               <div class="tw-flex">
                    <v-select v-model="selectedProductId" :loading="loading" :items="items" dense label="I would like to order" cache-items hide-details id="selectedProductId" item-text="title" item-value="id" />
               </div>
            </v-card-text>
        </v-card>
        <DynamicForm v-if="selectedProductId" form-url="ordering/products" :params="{ forms_id: selectedProductId }" hide-cancel custom-action-text="Buy" @submit="handleSubmit" />
    </v-container>
</template>

<script>
    import DynamicForm from '@/components/DynamicForm.vue'
    import Http from '@/utils/httpClient';

    export default {
        data() {
            return {
                selectedProductId: null,
                items: [],
                loading: false,
                formSchema: null,
            }
        },
        components: {
            DynamicForm,
        },
        async mounted() {
            await this.fetchForms()
        },
        methods: {
            async fetchForms() {
                await Http.get('ordering/products').then(({ data }) => {
                    this.loading = true;

                    this.items = data.forms;
                }).finally(() => {
                    this.loading = false;
                })
            },
            async handleSubmit(formValues) {
                await Http.post('ordering/products', formValues, {
                    params: {
                        form_id: this.selectedProductId
                    }
                }).then(({ data }) => {
                    this.loading = true;

                    this.items = data.forms;
                }).finally(() => {
                    this.loading = false;
                })
            },
          async postCallback(_data) {
              console.log('_data', _data);
          }
        }
    }
</script>
