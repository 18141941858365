<template>
    <div>
        <v-card v-if="!loading && Object.keys(form).length !== 0" flat class="mx-auto 2xl:tw-w-1/2 tw-px-6 tw-py-8">
            <v-card-title v-if="!loading">
                {{ form.title }}
            </v-card-title>
            <v-card-text>
                <json-forms
                    :data="schemaData"
                    :schema="schema"
                    :uischema="uischema"
                    :renderers="renderers"
                    @change="onJsonFormChange"
                />
            </v-card-text>
            <v-card-actions class="mt-8 d-flex justify-center">
                <v-btn
                    depressed
                    @click.native="$emit('cancel')"
                    color="secondary"
                    class="tw-px-10 tw-py-6"
                    v-if="!hideCancel"
                >
                    Cancel
                </v-btn>
                <v-spacer v-if="!hideCancel"/>
                <v-btn
                    dark
                    color="primary"
                    depressed
                    @click="submitHandler"
                    class="tw-px-10 tw-py-6"
                >
                    {{ actionText }}
                </v-btn>
              <!--
              <v-btn
                  dark
                  color="primary"
                  depressed
                  @click.native="$emit('submit', formData)"
                  class="tw-px-10 tw-py-6"
              >
                {{ actionText }}
              </v-btn>
              -->
            </v-card-actions>
        </v-card>
        <v-card v-else-if="loading" flat class="mx-auto 2xl:tw-w-1/2 tw-px-6 tw-py-8">
            <v-card-text>
                <div v-if="loading" class="tw-flex tw-justify-center tw-py-10">
                    <v-progress-circular indeterminate />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { JsonForms } from '@jsonforms/vue2'
import { vuetifyRenderers } from '@jsonforms/vue2-vuetify'
import Http from '@/utils/httpClient';

const renderers = [
    ...vuetifyRenderers
]

export default {
    name: 'DynamicForm',
    components: {
        JsonForms,
    },
    props: {
        customActionText: {
            type: String,
            default: ''
        },
        formUrl: {
            type: String,
            required: true
        },
        params: {
            type: Object,
            default: () => ({})
        },
        hideCancel: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            formData: {},
            schema: {},
            uischema: {},
            schemaData: {},
            form: {},
            loading: false,
            renderers: Object.freeze(renderers),
        }
    },
    watch: {
        formUrl: {
            immediate: true,
            deep: true,
            handler() {
                this.getFormSchema()
            }
        },
        params: {
            immediate: true,
            deep: true,
            handler() {
                this.getFormSchema()
            }
        }
    },
    computed: {
        actionText() {
            if (this.customActionText) return this.customActionText;

            return this.isNew ? 'Save' : 'Update'
        }
    },
    methods: {
        async getFormSchema() {
            this.loading = true;

            this.schema = {}
            this.uischema = {}
            this.schemaData = {}
            this.form = {}

            await Http.get(this.formUrl, {
                params: this.params
            }).then(({ data: { form, jsonschema } }) => {
                this.schema = jsonschema.schema;
                this.uischema = jsonschema.uischema;
                this.schemaData = jsonschema.data;
                this.form = form;
                this.schema.definitions = {}
            }).finally(() => {
                this.loading = false;
            })
        },
        onJsonFormChange(e) {
            this.formData = {
                forms_id: this.form.id,
                data: e.data,
            };
        },
        async submitHandler(_data) {
          console.log('this.formData', this.formData);
          console.log('this.formUrl', this.formUrl);

          await Http.post(this.formUrl, this.formData).then((data) => {
            console.log('submitResponse', data)
          }).finally(() => {
            this.loading = false;
          })
        }
    },
    async mounted() {
        await this.getFormSchema();
        if (this.schema.properties) {
            this.formData = {
                forms_id: this.form.id,
                data: {}
            }

            Object.entries(this.schema.properties).forEach(([id, field]) => {
                if (field.options && field.options.hidden) this.formData.data[id] = field.value;
                else this.formData.data[id] = field.default;
            })
        }
    }    
}
</script>

<style lang="scss" scoped>

</style>
